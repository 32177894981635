import { useContext, useState } from 'react';

import { Button, Checkbox, Form, Input, Select, Skeleton } from 'antd';

import styles from './fieldConfigDrawerComponent.module.scss';
import { FieldArrayProps } from 'types/model';
import AddRelation from './createField/AddRelation';
import DynamicTextfieldList from 'components/groupedInput/DynamicTextfieldList';
import { errorMessage, successMessage } from 'utils/message';
import {
  CreateFieldToModelMutation,
  Field_Sub_Type_Enum,
  Field_Type_Enum,
  useCreateFieldToModelMutation,
  useGetSettingsQuery,
} from 'generated/graphql';
import { capitalize } from 'lodash';
import CheckboxList from './createField/CheckboxList';
import { ContentContext } from 'context/ContentContext';
import { GET_FORM_GENERATION_DATA } from '../../../../../graphql/queries/contents';

const localsData = require('data/setting/locals.json');

const validateMessages = {
  // eslint-disable-next-line no-template-curly-in-string
  required: '${label} is required!',
};

const { Option } = Select;

const CreateField = (props) => {
  const { loading, error, data } = useGetSettingsQuery();
  if (error) {
    errorMessage(error);
    return <CreateFieldContainer {...props} />;
  }
  if (data) {
    return loading ? (
      <Skeleton active />
    ) : (
      <CreateFieldContainer localsListData={data?.currentProject?.settings?.locals} {...props} />
    );
  }
  return <CreateFieldContainer {...props} />;
};

type CreateFieldContainerProps = {
  field: FieldArrayProps;
  locals: string[] | [];
  repeatedFieldIdentifier: string;
  setIsFieldConfigDrawer: Function;
};

const CreateFieldContainer = ({
  field,
  // serial,
  setIsFieldConfigDrawer,
  locals,
  repeatedFieldIdentifier,
}: CreateFieldContainerProps) => {
  const { input_type, field_type, field_sub_type } = field;
  const [isLocal, setIsLocal] = useState(false);
  const { state } = useContext(ContentContext);

  const [identifier, setIdentifier] = useState('');

  const [form] = Form.useForm();
  const listType = ['string']; // for now only string
  const localsEntries =
    Object.entries(localsData)?.filter((item) => {
      if ((locals as string[])?.includes(item?.[0])) {
        return item;
      }
      return '';
    }) ?? [];

  const dynamicFormListBtnLabel =
    field_sub_type === 'dropdown'
      ? 'Dropdown Option'
      : field_sub_type === 'multiSelect'
      ? 'Select Option'
      : '';

  const updateCacheData = (cache, { data }: { data?: CreateFieldToModelMutation }) => {
    const resData = data.upsertFieldToModel;
    const { repeated_group_identifier } = resData || {};

    const query = {
      query: GET_FORM_GENERATION_DATA,
      variables: {
        model_name: `${state.target}`,
      },
    };

    const existingData = cache.readQuery({
      ...query,
    });

    if (existingData?.projectModelInfoByName?.fields?.length > 0) {
      let fieldsArray = [];
      if (repeated_group_identifier) {
        fieldsArray = [
          ...existingData?.projectModelInfoByName?.fields?.map((field) => {
            if (field.identifier === repeated_group_identifier) {
              return {
                ...field,
                sub_field_info: [...field?.sub_field_info, resData],
              };
            }
            return field;
          }),
        ];
      } else {
        fieldsArray = [...(existingData?.projectModelInfoByName?.fields || []), resData]; // Create a new array with resData appended
      }

      //console.log('fields array', fieldsArray);
      //console.log('repeated', repeated_group_identifier);

      try {
        cache.writeQuery({
          ...query,
          data: {
            projectModelInfoByName: Object.assign({}, existingData?.projectModelInfoByName, {
              fields: fieldsArray,
            }),
          },
        });
      } catch (error) {
        console.log('error', error);
      }
    }
  };

  const [createFieldToModel, { loading }] = useCreateFieldToModelMutation({
    onCompleted: (data) => {
      successMessage('New Field Created Successfully!');
      form.resetFields();
      setIsFieldConfigDrawer(false);
      setIsLocal(false);
    },
    update: updateCacheData,
  });

  const onFinish = async (values) => {
    await createFieldToModel({
      variables: {
        model_name: state.target,
        field_type,
        field_sub_type,
        input_type,
        // serial,
        repeated_group_identifier: repeatedFieldIdentifier || undefined,
        ...values,
      },
    });
  };

  const onFormValueChange = (values) => {
    const label = values?.field_label || '';
    if (label) {
      setIdentifier(
        label
          .toLocaleLowerCase()
          .trim()
          .replace(/[^A-Z0-9]+/gi, '_') || ''
      );
    }
  };

  return (
    <div className={styles.createField}>
      {field_type === 'relation' ? (
        <AddRelation />
      ) : (
        <Form
          name="field_setting"
          layout="vertical"
          onFinish={onFinish}
          form={form}
          validateMessages={validateMessages}
          onValuesChange={onFormValueChange}
          initialValues={{ remember: true }}
        >
          <div className={`${styles.topContent} mt-2`}>
            <Form.Item name="field_label" label="Field Label" rules={[{ required: true }]}>
              <Input placeholder="Ex: Name, Title, etc" />
            </Form.Item>
            <Form.Item label="Field Identifier" extra="Auto Generated & Used by API">
              <Input placeholder="Auto Generated Field Identifier" value={identifier} disabled />
            </Form.Item>
            <Form.Item name="field_description" label="Field Description">
              <Input placeholder="The purpose of the field" />
            </Form.Item>

            <Form.Item name={['validation', 'placeholder']} label="Hints">
              <Input />
            </Form.Item>

            <div className="mb-3">
              <CheckboxList field_sub_type={field_sub_type} field_type={field_type} />
            </div>
            <div>
              {field_type !== Field_Type_Enum.Geo &&
              field_type !== Field_Type_Enum.Date &&
              field_type !== Field_Type_Enum.Repeated &&
              field_type !== Field_Type_Enum.Boolean &&
              field_type !== Field_Type_Enum.Number &&
              field_type !== Field_Type_Enum.Media &&
              field_sub_type !== Field_Sub_Type_Enum.Dropdown &&
              field_sub_type !== Field_Sub_Type_Enum.MultiSelect ? (
                <Form.Item valuePropName="checked">
                  <Checkbox checked={isLocal} onChange={() => setIsLocal(!isLocal)}>
                    Enable Localization
                  </Checkbox>
                </Form.Item>
              ) : null}
            </div>
            {isLocal ? (
              <Form.Item
                name={['validation', 'locals']}
                style={{ marginTop: '-15px' }}
                extra="Add / Remove Languages from Setting > Addons > Localization"
              >
                <Select
                  mode="multiple"
                  placeholder="Select Language"
                  optionFilterProp="children"
                  allowClear
                >
                  {localsEntries.length > 0
                    ? localsEntries.map((d: [string, string], i) => (
                        <Option key={i} label={d[0]} value={d[0]}>
                          {d[1]}
                        </Option>
                      ))
                    : null}
                </Select>
              </Form.Item>
            ) : null}

            {field_type === Field_Type_Enum.List &&
            field_sub_type !== Field_Sub_Type_Enum.DynamicList ? (
              <div>
                <Form.Item name={['validation', 'list_type']} label="Field Type">
                  <Select placeholder="Select Type" allowClear>
                    {listType.map((item, index) => (
                      <Option value={item} key={index}>
                        {capitalize(item)}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item name={['validation', 'fixed_list_elements']} initialValue={['']}>
                  <DynamicTextfieldList btnLabel={dynamicFormListBtnLabel} />
                </Form.Item>
              </div>
            ) : null}
          </div>
          <Button
            type="primary"
            htmlType="submit"
            className={styles.updateFieldBtn}
            loading={loading}
          >
            CREATE FIELD
          </Button>
        </Form>
      )}
    </div>
  );
};

export default CreateField;
