import { useEffect, useRef, useState } from 'react';
import { Alert, Button, Card, Col, Divider, Empty, PageHeader, Row, Space } from 'antd';
import SubscriptionTable from './SubscriptionTable';
import { httpService } from 'utils/httpService';
import { BASE_URL, USER_SUB_CHECK, USER_SUB_FETCH } from 'configs';
import { errorMessage } from 'utils/message';
import PlanCard from '../../../components/cards/PlanCard';
import { useAuthContext } from '../../../hooks/useAuth';
import { PLANS_LIST } from '../../../constants/plansData';
import { CheckOutlined, CloudSyncOutlined } from '@ant-design/icons';

import styles from './subscription.module.scss';

const Subscription = (props) => {
  const { decodeTokenData } = useAuthContext();
  const tokenData = decodeTokenData();

  // @ts-ignore
  const { Paddle } = window;

  const [listSubscription, setListSubscription] = useState([]);
  const [isProjectFetching, setIsProjectFetching] = useState(false);

  const config = {
    withCredentials: true,
  };

  const fetchProjects = () => {
    setIsProjectFetching(true);
    httpService
      .post(USER_SUB_FETCH, {}, config)
      .then((res) => {
        const projects = res?.data?.body || [];
        setListSubscription(projects);
        setIsProjectFetching(false);
      })
      .catch((err) => {
        setIsProjectFetching(false);
        errorMessage(err?.response?.data?.message);
      });
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  const httpConfig = {
    withCredentials: true,
  };

  const onOpenModal = (plan) => {
    Paddle.Checkout.open({
      product: plan?.code,
      email: tokenData?.email,
      // passthrough: "{\"user_id\": "98765", \"project_id\": \"id\"}"
      passthrough: `{"user_id": "${tokenData.account}"}`,
    });
  };

  const checkPlan = (plan) => {
    httpService
      .post(
        USER_SUB_CHECK,
        {
          subscription_type: plan?.key,
          name: plan?.title,
          subscription_code: plan?.code.toString(),
          feature_promise: plan?.infoList,
        },
        httpConfig
      )
      .then((res) => {
        onOpenModal(plan);
      })
      .catch((err) => {
        console.log('error', err);
        errorMessage(err);
      });
  };

  const gridStyle: React.CSSProperties = {
    width: '50%',
    textAlign: 'center',
  };

  const freeItems = [
    'Unlimited Projects',
    '1 API Publish',
    '10K API Calls / Month',
    '100 MB Media CDN',
    '1,000 Records',
    '1 Seat',
    'GraphQL Server/API',
    'RESTful API',
    'Content Management API',
  ];

  const packages = [
    {
      title: 'Free',
      subtitle: 'for hobby projects',
      price: '$19/month',
      features: [
        '1 course to share privately',
        'No selling option',
        'No Social Marketing',
        'Courses & Pages are not discoverable',
        'No team of helpers',
        'No personal and page Blogs',
      ],
      transactionFee: 'No transaction fees',
      buttonText: 'Get started',
      buttonType: 'primary',
    },
    {
      title: 'Start',
      subtitle: 'for small projects',
      price: '$89/month',
      features: [
        '5 courses',
        'Can sell courses and charge users',
        'Marketing with social media',
        'Courses & Pages are discoverable',
        'No team of helpers',
        'Create your personal and page Blogs',
      ],
      transactionFee: '10% transaction fees + Stripe fees',
      buttonText: 'Get started',
      buttonType: 'default',
    },
    {
      title: 'Scale',
      subtitle: 'for growing projects',
      price: '$49/month',
      features: [
        'Unlimited courses',
        'Can sell courses and charge users',
        'Marketing with social media',
        'Courses & Pages are discoverable',
        'No team of helpers',
        'Create your personal and page Blogs',
      ],
      transactionFee: '8% transaction fees + Stripe fees',
      buttonText: 'Get started',
      buttonType: 'primary',
    },
  ];

  return (
    <div>
      <PageHeader title="Subscription" subTitle="Payment History">
        {/*<div>
          <Alert message="Info Text" type="info" />
        </div>
        <Divider></Divider>*/}

        <SubscriptionTable tableData={listSubscription} loading={isProjectFetching} />
        <Divider></Divider>
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          {PLANS_LIST.slice(0, 4).map((plan, i) => (
            <PlanCard
              item={plan}
              key={i}
              onPlanClicked={(plan) => {
                checkPlan(plan);
              }}
            />
          ))}
        </div>
      </PageHeader>
    </div>
  );
};

export default Subscription;
