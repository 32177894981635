import { Navigate, Route, Routes } from 'react-router-dom';

import LoginContainer from 'pages/Login';
import AuthCheck from 'components/routes/AuthCheck';

import ProjectsLayout from 'components/layouts/ProjectsLayout';
import ContentsContainer from 'pages/Console/Contents';
import ModelsContainer from 'pages/Console/Models';
import MediaContainer from 'pages/Console/Media';
import LogicContainer from 'pages/Console/Logic';
import AccountsPageContainer from 'pages/projects/Accounts';
import Subscription from 'pages/projects/Subscription';
import ProjectList from 'pages/projects/ProjectList';
import StartProject from 'pages/projects/StartProject';
import Support from 'pages/projects/Support';
import Admin from 'pages/projects/Admin';
import SystemExplorer from 'pages/projects/Explorer';
import SettingsLayout from 'components/layouts/SettingsLayout';
import TeamsContainer from 'pages/Console/Settings/teams';
import ApiUsagesContainer from 'pages/Console/Settings/apiUsages';
import ConsoleLayout from 'components/layouts/ConsoleLayout';
import ContentsLayout from 'components/layouts/ContentsLayout';
import PluginsContainer from 'pages/Console/Settings/plugins';
import WebhooksContainer from 'pages/Console/Settings/webhooks';
import RolesContainer from 'pages/Console/Settings/roles';
import GeneralContainer from 'pages/Console/Settings';
import APIExplorerContainer from 'pages/apiExplorer';
import ApiSecretsContainer from 'pages/Console/Settings/apiSecrets';
import RegistrationContainer from 'pages/Registration';
import Verification from 'pages/Varification';
import { useAuthContext } from './hooks/useAuth';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import ChangePassword from './pages/ChangePassword/ChangePassword';

//import insp, { install } from 'inspectlet-es';
import Teams from './pages/projects/Users';
import Audit from './pages/projects/Audit';
import GoogleCallbackContainer from './pages/GoogleCallback';
import GithubCallbackContainer from './pages/GithubCallback';
import Sync from 'pages/projects/Sync';

const ApitoRoutes = () => {
  const { decodeTokenData } = useAuthContext();
  const tokenData = decodeTokenData();

  /*install(1679513701); // Substitute your ID
  //console.log('token Data ->', tokenData);
  insp(['identify', tokenData?.email]);
  insp([
    'tagSession',
    {
      email: tokenData?.email,
      project: tokenData?.project_id,
      userid: tokenData?.account,
    },
  ]);*/

  return (
    <Routes>
      <Route path="/login" element={<LoginContainer />} />
      <Route path="/register" element={<RegistrationContainer />} />
      <Route path="/google/callback" element={<GoogleCallbackContainer />} />
      <Route path="/github/callback" element={<GithubCallbackContainer />} />
      <Route path="/verify" element={<Verification />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/change-password" element={<ChangePassword />} />
      <Route path="/" element={<AuthCheck />}>
        <Route index element={<Navigate to="projects" replace />} />
        <Route path="projects" element={<ProjectsLayout tokenData={tokenData} />}>
          <Route index element={<ProjectList />} />
          <Route path="start" element={<StartProject />} />
          <Route path="accounts" element={<AccountsPageContainer />} />
          <Route path="subscription" element={<Subscription />} />
          <Route path="support" element={<Support />} />
          <Route path="sync" element={<Sync />} />
          {tokenData?.project_role === 'admin' ? <Route path="users" element={<Teams />} /> : null}
          {tokenData?.is_super_admin === 'true' ? (
            <>
              <Route path="admin" element={<Admin />} />
              <Route path="explorer" element={<SystemExplorer />} />
              <Route path="audit-log" element={<Audit />} />
            </>
          ) : null}
        </Route>
        <Route path="console" element={<ConsoleLayout />}>
          {tokenData?.project_access?.includes('') && (
            <Route path="contents" element={<ContentsLayout />}>
              <Route path=":model" element={<ContentsContainer />} />
              <Route index element={<ContentsContainer />} />
            </Route>
          )}
          {tokenData?.project_access?.includes('model') && (
            <Route path="models">
              <Route path=":model" element={<ModelsContainer />} />
              <Route index element={<ModelsContainer />} />
            </Route>
          )}
          {tokenData?.project_access?.includes('media') && (
            <Route path="media" element={<MediaContainer />} />
          )}
          {tokenData?.project_access?.includes('logic') && (
            <Route path="logic">
              <Route path=":logic" element={<LogicContainer />} />
              <Route index element={<LogicContainer />} />
            </Route>
          )}
          {tokenData?.project_access?.includes('api_explorer') && (
            <Route path="api-explorer" element={<APIExplorerContainer />} />
          )}
          {tokenData?.project_access?.includes('settings') && (
            <Route path="settings" element={<SettingsLayout />}>
              <Route index element={<GeneralContainer />} />
              <Route path="api-usages" element={<ApiUsagesContainer />} />
              <Route path="teams" element={<TeamsContainer />} />
              <Route path="extensions" element={<PluginsContainer />} />
              <Route path="webhooks" element={<WebhooksContainer />} />
              <Route path="roles" element={<RolesContainer />} />
              <Route path="api-secrets" element={<ApiSecretsContainer />} />
            </Route>
          )}
          <Route index element={<Navigate to="/console/contents" replace />} />
        </Route>
      </Route>
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default ApitoRoutes;
