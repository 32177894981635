import { useContext, useState } from 'react';

import { Button, Checkbox, Form, Input, Select, Skeleton, Tooltip } from 'antd';

import styles from '../modelContainer/modelContainer.module.scss';
import DrawerDynamicFormList from '../modelContainer/DrawerDynamicFormList';
import {
  ProjectModel,
  useGetCurrentProjectQuery,
  useUpdateFieldToModelMutation,
} from 'generated/graphql';
import { capitalize } from 'lodash';
import RenderCheckbox from './RenderCheckbox';
import { errorMessage, successMessage } from 'utils/message';
import { ContentContext } from 'context/ContentContext';
import { GET_FORM_GENERATION_DATA } from '../../../../graphql/queries/contents';
const localsData = require('data/setting/locals.json');

const { Option } = Select;

const FieldReconfigurationDrawerComponent = (props) => {
  const { loading, error, data } = useGetCurrentProjectQuery();
  if (error) {
    errorMessage(error);
    return <FieldReconfigurationDrawerComponent {...props} />;
  }
  return loading ? (
    <Skeleton />
  ) : (
    <FieldReconfigurationDrawerComponentContainer localsListData={data} {...props} />
  );
};

type FieldReconfigurationDrawerComponentContainerProps = {
  projectInfo: ProjectModel;
  fieldData: {
    label: string;
    identifier: string;
    description: string;
    validation: { locals: string; fixed_list_elements: string[]; placeholder: string };
    field_type: string;
    serial: number;
  };
  repeatedFieldIdentifier: string;
  onSettingDrawerClose: Function;
};

const FieldReconfigurationDrawerComponentContainer = ({
  fieldData,
  projectInfo,
  repeatedFieldIdentifier,
  onSettingDrawerClose,
}: FieldReconfigurationDrawerComponentContainerProps) => {
  const { state } = useContext(ContentContext);

  const localsList = projectInfo?.settings?.locals || [];

  const { field_type, serial, validation, label, description } = fieldData;
  const { locals, fixed_list_elements, placeholder } = validation ?? {};

  const [is_local, setIs_local] = useState(locals?.length > 0);

  const checkboxList = ['required', 'hide', 'is_email', 'is_multi_choice', 'is_gallery', 'is_url'];
  const listType = ['int', 'double', 'string'];

  const localsEntries =
    Object.entries(localsData)?.filter((item: [string, string]): [string, string] => {
      if (localsList?.includes(item?.[0])) {
        return item;
      }
      return ['', ''];
    }) ?? [];
  const initialLocalArr = locals?.length > 0 ? locals : [];
  const dynamicFormListBtnLabel = field_type === 'list' ? `${label} Option` : '';

  const updateCacheData = (cache, { data }) => {
    const resData = data.upsertFieldToModel;
    const { repeated_group_identifier } = resData || {};
    const { serial } = resData;

    const query = {
      query: GET_FORM_GENERATION_DATA,
      variables: {
        model_name: `${state.target}`,
      },
    };

    const existingData = cache.readQuery({
      ...query,
    });

    cache.writeQuery({
      ...query,
      data: {
        projectModelInfoByName: {
          fields: [
            ...existingData?.projectModelInfoByName?.fields?.map((field) => {
              if (field.identifier === repeated_group_identifier) {
                return {
                  ...field,
                  sub_field_info:
                    field?.sub_field_info.map((item) => {
                      if (item.identifier === resData.identifier) {
                        return { ...item, ...resData };
                      }
                      return item;
                    }) ?? [],
                };
              }
              if (field.serial === serial) return { ...field, ...resData };
              return field;
            }),
          ],
        },
      },
    });
  };

  const [updateFieldToModel, { loading }] = useUpdateFieldToModelMutation({
    onCompleted: () => {
      successMessage('Field Updated Successfully');
      onSettingDrawerClose();
    },
    update: updateCacheData,
  });

  const onFinish = (values) => {
    updateFieldToModel({
      variables: {
        model_name: state.target,
        field_label: label,
        serial,
        is_update: true,
        repeated_group_identifier: repeatedFieldIdentifier || undefined,
        ...values,
      },
    });
  };

  return (
    <div key={fieldData.serial} className={styles.connectionSettingDrawerContainer}>
      <Form
        name="field_setting"
        layout="vertical"
        onFinish={onFinish}
        // onFinishFailed={onFinish}
        initialValues={{ remember: true }}
      >
        <Form.Item name="field_description" label="Field Description" initialValue={description}>
          <Input placeholder="Write down the purpose of the field" />
        </Form.Item>

        <Form.Item name={['validation', 'placeholder']} label="Hints" initialValue={placeholder}>
          <Input />
        </Form.Item>

        <div className="mb-3">
          <RenderCheckbox checkboxList={checkboxList} validation={validation} />
        </div>

        {field_type !== 'media' ? (
          <div>
            {field_type !== 'geo' &&
            field_type !== 'date' &&
            field_type !== 'repeated' &&
            field_type !== 'boolean' &&
            field_type !== 'number' &&
            field_type !== 'media' &&
            !(field_type === 'list' && validation?.fixed_list_elements.length > 0) ? (
              <Form.Item valuePropName="checked">
                <Checkbox checked={is_local} onChange={() => setIs_local(!is_local)}>
                  Enable Localization
                </Checkbox>
              </Form.Item>
            ) : null}
          </div>
        ) : null}

        {is_local ? (
          <Tooltip title="Add more language from Setting>Addons>Localization">
            <Form.Item name={['validation', 'locals']} initialValue={initialLocalArr}>
              <Select
                mode="multiple"
                placeholder="Select Language"
                optionFilterProp="children"
                allowClear
              >
                {localsEntries.length > 0
                  ? localsEntries.map((d: [string, string], i) => (
                      <Option key={i} label={d[0]} value={d[0]}>
                        {d[1]}
                      </Option>
                    ))
                  : null}
              </Select>
            </Form.Item>
          </Tooltip>
        ) : null}

        {field_type === 'list' &&
        !(field_type === 'list' && validation?.fixed_list_elements.length === 0) ? (
          <div>
            <Form.Item name={['validation', 'list_type']} label="Field Type">
              <Select placeholder="Select Type" allowClear>
                {listType.map((item, index) => (
                  <Option value={item} key={index}>
                    {capitalize(item)}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name={['validation', 'fixed_list_elements']}
              initialValue={[...fixed_list_elements] || []}
            >
              <DrawerDynamicFormList btnLabel={dynamicFormListBtnLabel} />
            </Form.Item>
          </div>
        ) : null}

        <Button
          type="primary"
          htmlType="submit"
          className={styles.updateFieldBtn}
          loading={loading}
        >
          UPDATE FIELD
        </Button>
      </Form>
    </div>
  );
};

export default FieldReconfigurationDrawerComponent;
