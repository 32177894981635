import { Badge, Button, Card, Tag, Typography } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

import styles from './cards.module.scss';
import { number } from 'yup';

type PlanCardProps = {
  item?: {
    key: number;
    title: string;
    price: number | string;
    subtitle: string;
    btnLabel?: string;
    isPlan: boolean;
    infoList: string[];
    previousPrice?: number | string;
  };
  onPlanClicked?: (item: {}) => void;
};

const PlanCard: React.FC<PlanCardProps> = ({ item, onPlanClicked }, cardHeight: string) => {
  const { Text } = Typography;

  return (
    <Card
      title={item.key === 4 ? <Badge count={item?.title} /> : <Text strong>{item?.title}</Text>}
      style={{ width: 300, textAlign: 'center' }}
      headStyle={{ border: 'none' }}
      bodyStyle={{ paddingTop: 0 }}
      className={styles.priceCard}
      hoverable
      extra={item.key === 3 ? <Tag color="red">Hot</Tag> : null}
    >
      <div className={styles.cardBody} style={{ minHeight: '188px' }}>
        <div style={{ marginBottom: '15px' }}>
          <Text>{item?.subtitle}</Text>
        </div>

        <div>
          <div className={styles.priceWrapper}>
            {item.previousPrice ? (
              <del>
                <Text>
                  <span className={styles.prevPrice}>${item.previousPrice}</span> /Month
                </Text>
              </del>
            ) : null}
            {item.key === 5 ? (
              <Text>
                <span>
                  drop an e-mail at <br />
                  <strong>fahim@apito.io</strong>
                </span>
              </Text>
            ) : (
              <Text>
                <span className={styles.price}>
                  {item.price === 0
                    ? `FREE`
                    : typeof item.price === 'number'
                    ? `$${item.price}`
                    : `${item.price}`}
                </span>
              </Text>
            )}
          </div>
          <Button
            type="primary"
            className={styles.cardBtn}
            onClick={() => {
              if (item.key === 5) {
                window.open('mailto:fahim@apito.io');
              } else {
                onPlanClicked(item);
              }
            }}
          >
            {item?.btnLabel ?? ''}
          </Button>
          {item?.isPlan && item?.infoList?.length ? (
            <div className={styles.infoListWrapper}>
              <ul>
                {item?.infoList.map((listItem: { includes: (a: string) => {} }, j) => (
                  <li key={j}>
                    <CheckOutlined
                      twoToneColor={listItem.includes('--') ? 'grey' : '#E73A55'}
                      className={styles.checkIcon}
                    />
                    <>{listItem}</>
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
        </div>
      </div>
    </Card>
  );
};

export default PlanCard;
