import { useState } from 'react';

import {
  Button,
  Collapse,
  Divider,
  Drawer,
  Empty,
  Layout,
  PageHeader,
  Skeleton,
  Spin,
  Typography,
} from 'antd';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import pluralize from 'pluralize';
import { PlusOutlined, TagsOutlined } from '@ant-design/icons';

import styles from './contents.module.scss';
import { capitalize } from 'lodash';
import { useGetFormGenerationDataQuery, useUpdateContentFormMutation } from 'generated/graphql';
import { errorMessage, successMessage } from 'utils/message';
import Contents from './Contents';
import TableGenerator from './TableGenerator';

dayjs.extend(relativeTime);

const { Content } = Layout;

const EditRelationForm = (props) => {
  const { passedContentData } = props;
  const { loading, error, data } = useGetFormGenerationDataQuery({
    variables: {
      model_name: `${passedContentData?.model}`,
    },
    // skip: !passedContentData?.id,
  });

  // if (contentSiderList?.listModelData?.length <= 0) return <div style={{ margin: '30vh auto 0 auto' }}><EmptyContent model={target} setIsCreate={setIsCreate} /></div>
  if (!passedContentData?.id) {
    return <div style={{ margin: '35vh auto 0 auto' }}>{/* <SelectContent /> */}</div>;
  }

  if (loading) return <Skeleton active />;
  if (error) {
    errorMessage(error);
    return <Empty />;
  }

  if (data) {
    const modelInfo = data?.projectModelsInfo[0];
    // console.log('Log: projectModelInfo', projectModelInfo)
    return <EditRelationFormWrapper modelInfo={modelInfo} {...props} />;
  }
  return null;
};

const { Text } = Typography;
const { Panel } = Collapse;

const EditRelationFormWrapper = ({ passedContentData, modelInfo }) => {
  const [drawerTitle, setDrawerTitle] = useState(undefined);
  const [drawerComponent, setDrawerComponent] = useState(undefined);

  const [newSelectedRowKeys, setNewSelectedRowKeys] = useState([]);

  const [drawerVisible, setDrawerVisible] = useState(false);

  const [loading, setLoading] = useState(false);

  const [newRowData, setNewRowData] = useState(undefined);

  const [upsertModelData, { loading: loadingTag }] = useUpdateContentFormMutation({
    onCompleted: () => {
      successMessage(`${capitalize(passedContentData?.model)} tagged`);
    },
    // update: updateCache
  });

  const getConnectionField = (connectionItem, index) => {
    const modelName = connectionItem?.model;
    const actualRelationName = capitalize(
      connectionItem?.relation === 'has_many' ? pluralize(modelName) : modelName
    );

    // push the _id into the object
    const obj = {
      _id: passedContentData?.id,
      to_model: modelInfo.name,
      model: connectionItem.model,
      relation_type: connectionItem.relation,
      known_as: connectionItem.known_as,
      connection_type: connectionItem.type,
    };
    // return <ContentContainer connection={obj} />;
    return (
      <Spin spinning={loading}>
        <PageHeader
          title={`Tagged ${capitalize(connectionItem?.model)}`}
          extra={
            <div>
              {newSelectedRowKeys?.length > 0
                ? [
                    <Button
                      icon={<TagsOutlined />}
                      className={styles.createBtn}
                      loading={loading}
                      onClick={async (e) => {
                        await setLoading(true);
                        console.log('untag', connectionItem);
                        const model =
                          connectionItem?.known_as !== ''
                            ? connectionItem?.known_as
                            : connectionItem?.model;
                        const key = model.concat(
                          connectionItem?.relation === 'has_many' ? '_ids' : '_id'
                        );
                        const disconnect = {};
                        disconnect[key] =
                          connectionItem?.relation === 'has_many'
                            ? newSelectedRowKeys
                            : newSelectedRowKeys[0];
                        await upsertModelData({
                          variables: {
                            _id: passedContentData?.id,
                            model_name: passedContentData?.model,
                            disconnect,
                          },
                        });
                        await setNewRowData({
                          type: connectionItem?.model,
                        });
                        await setLoading(false);
                        await setDrawerVisible(false);
                        await setNewSelectedRowKeys([]);
                        await setNewRowData(undefined);
                      }}
                    >
                      {`UNTAG SELECTED ${actualRelationName}`.toUpperCase()}
                    </Button>,
                    <Divider type="vertical" />,
                  ]
                : null}
              <Button
                type="primary"
                icon={<PlusOutlined />}
                className={styles.createBtn}
                onClick={(e) => {
                  setDrawerTitle([
                    <Text>
                      {' '}
                      Tag {actualRelationName} to {capitalize(obj?.to_model)}
                    </Text>,
                  ]);
                  setDrawerComponent(
                    <Contents
                      // selectedRowKeys={{}}
                      loadingTag={loadingTag}
                      connection={obj}
                      fetchIntersectResult // push the intersect as true to get an intersection result
                      fetchNewSelectedRowKeys={async (keys) => {
                        await setLoading(true);
                        const model =
                          connectionItem?.known_as !== ''
                            ? connectionItem?.known_as
                            : connectionItem?.model;
                        const key = model.concat(
                          connectionItem?.relation === 'has_many' ? '_ids' : '_id'
                        );
                        const connect = {};
                        connect[key] = connectionItem?.relation === 'has_many' ? keys : keys[0];
                        await upsertModelData({
                          variables: {
                            _id: passedContentData?.id,
                            model_name: passedContentData?.model,
                            connect,
                          },
                        });
                        await setNewRowData({
                          type: connectionItem?.model,
                        });
                        await setLoading(false);
                        await setDrawerVisible(false);
                        await setNewRowData(undefined);
                      }}
                    />
                  );
                  setNewRowData({
                    type: connectionItem?.model,
                  });
                  setDrawerVisible(true);
                }}
              >
                {`TAG ${actualRelationName}`.toUpperCase()}
              </Button>
            </div>
          }
        >
          <TableGenerator
            // oldSelectedRowKeys={selectedRowKeys}
            connection={obj}
            modelName={connectionItem?.model}
            newRowData={newRowData}
            setNewSelectedRowKeys={(keys) => {
              setNewSelectedRowKeys(keys);
            }}
          />
        </PageHeader>
      </Spin>
    );

    /* <CustomSelectForConnection
                connectionItem={connectionItem}
                fromModel={projectModelInfo.name}
                Model_id={formData?._key}
                key={index}
            /> */
  };

  if (modelInfo?.fields?.length === 0 || !modelInfo) return <Empty />;
  if (passedContentData?.model !== modelInfo?.name) return <Skeleton />;

  return (
    <Content key={`${passedContentData?.id}`} className={styles.contentFormContainer}>
      {/* <div style={{ marginTop: '5px' }}>{renderForm(projectModelInfo)}</div> */}
      <div style={{ marginTop: '10px' }}>
        {modelInfo?.connections?.length > 0 ? (
          <Collapse bordered={false}>
            {modelInfo.connections.map((connectionItem, index) => {
              let label = '';
              const cT = connectionItem.type === 'forward' ? 'as' : 'in';
              if (connectionItem.known_as) {
                label =
                  connectionItem.relation === 'has_many'
                    ? `${pluralize(connectionItem.known_as)} ${cT} ${connectionItem.model}`
                    : `${connectionItem.known_as} ${cT} ${connectionItem.model}`;
              } else {
                label =
                  connectionItem.relation === 'has_many'
                    ? pluralize(connectionItem.model)
                    : connectionItem.model;
              }
              return (
                <Panel header={`${capitalize(label)} (${connectionItem.relation}) `} key={index}>
                  {getConnectionField(connectionItem, index)}
                </Panel>
              );
            })}
          </Collapse>
        ) : (
          'No Relation Found'
        )}
      </div>
      <Drawer // Default Drawer
        title={drawerTitle}
        width="60%"
        bodyStyle={{ padding: '5px 24px 24px' }}
        closable
        destroyOnClose
        onClose={() => setDrawerVisible(false)}
        visible={drawerVisible}
        extra={<Button type="link" onClick={() => setDrawerVisible(false)}>{`< Back`}</Button>}
      >
        {drawerComponent}
      </Drawer>
    </Content>
  );
};

export default EditRelationForm;
